// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import { useState,useEffect } from "react";
import { useMoralis } from "react-moralis";

const Header = () => {

  const [dataStats, setDataStats] = useState([]);
  const { Moralis,isWeb3Enabled, enableWeb3 } =  useMoralis();

  useEffect(() => {
    const getOrderPerformance = async () => {
      !isWeb3Enabled &&  await enableWeb3();
      const user = Moralis.User.current();
      console.log(user);
      if(user && user.get('role') === 'admin'){
        const data= await Moralis.Cloud.run("getStats");
        if(data) {
          setDataStats(data);
        } else {
          console.log("error");
        }
      }
    };
    getOrderPerformance();
  }, [Moralis.Cloud, Moralis.User, enableWeb3, isWeb3Enabled]);

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            {/* Card stats */}
            <Row>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Whitelisted
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {dataStats.whiteListed}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                          <i className="fas fa-chart-bar" />
                        </div>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-muted text-sm">
                    <span className={dataStats.whiteListedGrowth>0?"text-success mr-2":"text-warning mr-2"}>
                         <i className={dataStats.whiteListedGrowth>0?"fas fa-arrow-up":"fas fa-arrow-down"} />
                    </span>{" "}{dataStats.whiteListedGrowth>0?dataStats.whiteListedGrowth:(dataStats.whiteListedGrowth*-1)}%{" "}
                      
                      <span className="text-nowrap">Since last month</span>
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          New users
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">{dataStats.newUsers}</span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                          <i className="fas fa-chart-pie" />
                        </div>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-muted text-sm">
                      <span className={dataStats.newUsersGrowth>0?"text-success mr-2":"text-warning mr-2"}>
                         <i className={dataStats.newUsersGrowth>0?"fas fa-arrow-up":"fas fa-arrow-down"} />
                      </span>{" "}{dataStats.newUsersGrowth>0?dataStats.newUsersGrowth:(dataStats.newUsersGrowth*-1)}%{" "}
                      <span className="text-nowrap">Since last week</span>
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Sales
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">{dataStats.sales}</span>
                        </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                          <i className="fas fa-users" />
                        </div>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-muted text-sm">
                        <span className={dataStats.salesGrowth>0?"text-success mr-2":"text-warning mr-2"}>
                         <i className={dataStats.salesGrowth>0?"fas fa-arrow-up":"fas fa-arrow-down"} />
                      </span>{" "}{dataStats.salesGrowth>0?dataStats.salesGrowth:(dataStats.salesGrowth*-1)}%{" "}
                      <span className="text-nowrap">Since yesterday</span>
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Performance
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">{dataStats.performance}%</span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                          <i className="fas fa-percent" />
                        </div>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-muted text-sm">
                        <span className={dataStats.performanceGrowth>0?"text-success mr-2":"text-warning mr-2"}>
                         <i className={dataStats.performanceGrowth>0?"fas fa-arrow-up":"fas fa-arrow-down"} />
                        </span>{" "}{dataStats.performanceGrowth>0?dataStats.performanceGrowth:(dataStats.performanceGrowth*-1)}% {" "}
                      <span className="text-nowrap">Since last month</span>
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Header;
