import { useState,useEffect } from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import { useMoralis } from "react-moralis";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
 // chartExample2,
} from "variables/charts.js";

import Header from "components/Headers/Header.js";

const Index = (props) => {
  const [activeNav, setActiveNav] = useState(1);
  const [chartExample1Data, setChartExample1Data] = useState("data1");

  const [twitterFollower, setTwitterFollower] = useState(0);
  const [facebookFollower, setFacebookFollower] = useState(0);
  const [instagramFollower, setInstagramFollower] = useState(0);
  const [youtubeFollower, setYoutubeFollower] = useState(0);
  const [WebsiteVisitor, setWebsiteVisitor] = useState(0);

  const [twitterFollowerGrowth, setTwitterFollowerGrowth] = useState(0);
  const [facebookFollowerGrowth, setFacebookFollowerGrowth] = useState(0);
  const [instagramFollowerGrowth, setInstagramFollowerGrowth] = useState(0);
  const [youtubeFollowerGrowth, setYoutubeFollowerGrowth] = useState(0);
  const [WebsiteVisitorGrowth, setWebsiteVisitorGrowth] = useState(0);

  const [nftBuyers, setNftBuyers] = useState([]);
  const { Moralis,isWeb3Enabled, enableWeb3, isAuthenticated } =  useMoralis();

  const [totalOrdersChart, setTotalOrdersChart] = useState();
    useEffect(() => {

      const getOrderPerformance = async () => {
        console.log("getOrderPerformance");
        !isWeb3Enabled &&  await enableWeb3();
        const user = Moralis.User.current();
        let ordersPerformance = [];
        
        if(user && user.get('role') === 'admin') {
            ordersPerformance = await Moralis.Cloud.run("getOrdersPerformance");
            if(ordersPerformance) {
              let totalOrders = {
                options: {
                  scales: {
                    yAxes: [
                      {
                        ticks: {
                          callback: function (value) {
                            if (!(value % 10)) {
                              return value;
                            }
                          },
                        },
                      },
                    ],
                  },
                  tooltips: {
                    callbacks: {
                      label: function (item, data) {
                        var label = data.datasets[item.datasetIndex].label || "";
                        var yLabel = item.yLabel;
                        var content = "";
                        if (data.datasets.length > 1) {
                          content += label;
                        }
                        content += yLabel;
                        return content;
                      },
                    },
                  },
                },
                data: {
                  labels: ordersPerformance.months,
                  datasets: [
                    {
                      label: "Sales",
                      data: ordersPerformance.sales,
                      maxBarThickness: 10,
                    },
                  ],
                },
              };
              setTotalOrdersChart(
              <Bar
                    data={totalOrders.data}
                    options={totalOrders.options}
                  />
              );
            }else{
              console.log("no orders performance");
            }
        }
      };

    const getSocialMetrics = async () => {
      console.log("getSocialMetrics");
      !isWeb3Enabled &&  await enableWeb3();
      const user = Moralis.User.current();
      if(user && user.get('role') === 'admin') {
        const socialMetrics = await Moralis.Cloud.run("getSocialMetrics");
        if(socialMetrics) {
          setTwitterFollower(socialMetrics.twitterFollower);
          setFacebookFollower(socialMetrics.facebookFollower);
          setInstagramFollower(socialMetrics.instagramFollower);
          setYoutubeFollower(socialMetrics.youtubeFollower);
          setWebsiteVisitor(socialMetrics.websiteVisitor);

          setTwitterFollowerGrowth(socialMetrics.twitterFollowerGrowth);
          setFacebookFollowerGrowth(socialMetrics.facebookFollowerGrowth);
          setInstagramFollowerGrowth(socialMetrics.instagramFollowerGrowth);
          setYoutubeFollowerGrowth(socialMetrics.youtubeFollowerGrowth);
          setWebsiteVisitorGrowth(socialMetrics.websiteVisitorGrowth);
          
        }else{
          console.log("Error");
        }
      }
    }

    const getNFTBuyers = async () => {
      console.log("getNFTBuyers");
       !isWeb3Enabled &&  await enableWeb3();
      const user = Moralis.User.current();
      if(user && user.get('role') === 'admin') {
        const nftBuyers = await Moralis.Cloud.run("getNFTBuyers");
        if(nftBuyers) {
          setNftBuyers(nftBuyers);
        }else{
          console.log("Error");
        }
      }
    }

    getSocialMetrics();
    getNFTBuyers();
    getOrderPerformance();
  }, [Moralis.Cloud, Moralis.User, enableWeb3, isAuthenticated, isWeb3Enabled]);


  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
    setChartExample1Data("data" + index);
  };

 

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="mb-5 mb-xl-0" xl="8">
            <Card className="bg-gradient-default shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase text-light ls-1 mb-1">
                      Overview
                    </h6>
                    <h2 className="text-white mb-0">Sales value</h2>
                  </div>
                  <div className="col">
                    <Nav className="justify-content-end" pills>
                      <NavItem>
                        <NavLink
                          className={classnames("py-2 px-3", {
                            active: activeNav === 1,
                          })}
                          href="#pablo"
                          onClick={(e) => toggleNavs(e, 1)}
                        >
                          <span className="d-none d-md-block">Month</span>
                          <span className="d-md-none">M</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames("py-2 px-3", {
                            active: activeNav === 2,
                          })}
                          data-toggle="tab"
                          href="#pablo"
                          onClick={(e) => toggleNavs(e, 2)}
                        >
                          <span className="d-none d-md-block">Week</span>
                          <span className="d-md-none">W</span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                {/* Chart */}
                <div className="chart">
                  <Line
                    data={chartExample1[chartExample1Data]}
                    options={chartExample1.options}
                    getDatasetAtEvent={(e) => console.log(e)}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl="4">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase text-muted ls-1 mb-1">
                      Performance
                    </h6>
                    <h2 className="mb-0">Total orders</h2>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                {/* Chart */}
                <div className="chart">
                  {totalOrdersChart}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="8">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Latest NFT</h3>
                  </div>
                  <div className="col text-right">
                    <Button
                      color="primary"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                      size="sm"
                    >
                      See all
                    </Button>
                  </div>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Buyer Addr</th>
                    <th scope="col">NFT Token</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Date</th>
                  </tr>
                </thead>
                <tbody>
                  {nftBuyers.map((nftBuyer, index) => (
                    <tr key={index}>
                      <th scope="row">

                        <div className="media align-items-center">
                          <div className="media-body">
                            <span className="mb-0 text-sm">{nftBuyer.buyerAddr}</span>
                          </div>
                        </div>
                      </th>
                      <td>
                        <span className="badge badge-dot mr-4">
                          #{nftBuyer.nftToken}
                        </span>
                      </td>
                      <td>{nftBuyer.amount}</td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">{nftBuyer.date}</span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          </Col>
          <Col xl="4">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Social traffic</h3>
                  </div>
                  <div className="col text-right">
                    <Button
                      color="primary"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                      size="sm"
                    >
                      See all
                    </Button>
                  </div>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Platform</th>
                    <th scope="col">Followers</th>
                    <th scope="col">Growth</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">Facebook</th>
                    <td>{facebookFollower}</td>
                    <td>
                      <div className="d-flex align-items-center">
                        <span className="mr-2">{facebookFollowerGrowth}%</span>
                        <div>
                          <Progress
                            max="100"
                            value={facebookFollowerGrowth}
                            barClassName="bg-gradient-danger"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Website</th>
                    <td>{WebsiteVisitor}</td>
                    <td>
                      <div className="d-flex align-items-center">
                        <span className="mr-2">{WebsiteVisitorGrowth}%</span>
                        <div>
                          <Progress
                            max="100"
                            value={WebsiteVisitorGrowth}
                            barClassName="bg-gradient-success"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Youtube</th>
                    <td>{youtubeFollower}</td>
                    <td>
                      <div className="d-flex align-items-center">
                        <span className="mr-2">{youtubeFollowerGrowth}%</span>
                        <div>
                          <Progress max="100" value={youtubeFollowerGrowth} />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Instagram</th>
                    <td>{instagramFollower}</td>
                    <td>
                      <div className="d-flex align-items-center">
                        <span className="mr-2">{instagramFollowerGrowth}%</span>
                        <div>
                          <Progress
                            max="100"
                            value={instagramFollowerGrowth}
                            barClassName="bg-gradient-info"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">twitter</th>
                    <td>{twitterFollower}</td>
                    <td>
                      <div className="d-flex align-items-center">
                        <span className="mr-2">{twitterFollowerGrowth}%</span>
                        <div>
                          <Progress
                            max="100"
                            value={twitterFollowerGrowth}
                            barClassName="bg-gradient-warning"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Index;
