/*eslint-disable*/

// reactstrap components
import { NavItem, NavLink, Nav, Container, Row, Col } from "reactstrap";

const Login = () => {
  return (
    <>
      <footer className="py-5">
        <Container>
          <Row className="align-items-center justify-content-xl-between">
            <Col xl="6">
              <div className="copyright text-center text-xl-left text-muted">
                © {new Date().getFullYear()}{" "}
                <a
                  className="font-weight-bold ml-1"
                  href="https://www.creative-tim.com?ref=adr-auth-footer"
                  target="_blank"
                >
                  Falcon Eye Club
                </a>
              </div>
            </Col>
            <Col xl="6">
              <Nav className="nav-footer justify-content-center justify-content-xl-end">
              <NavItem>
              <NavLink
                href="https://www.falconeye.io"
                rel="noopener noreferrer"
                target="_blank"
              >
                Etherscan
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                href="https://twitter.com/FalconEyeClub"
                rel="noopener noreferrer"
                target="_blank"
              >
                Twitter
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="https://www.instagram.com/falconeye.io/"
                rel="noopener noreferrer"
                target="_blank"
              >
                Instagram
              </NavLink>
            </NavItem>      
            <NavItem>
              <NavLink
                href="https://www.instagram.com/falconeye.io/"
                rel="noopener noreferrer"
                target="_blank"
              >
                Youtube
              </NavLink>
            </NavItem>      
            <NavItem>
              <NavLink
                href="https://github.com/alsaibai/falconeye.io.git"
                rel="noopener noreferrer"
                target="_blank"
              >
                GitHub
              </NavLink>
            </NavItem>
              </Nav>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};

export default Login;
